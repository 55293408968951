@use "../../../tokens";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.mainMenu {
  display: none;
  overflow: hidden;

  @media screen and (min-width: tokens.$screen-xl) {
    display: flex;
    gap: tokens.$layout-sm;
    flex-direction: column;
    flex: 0 0 auto;
    padding-bottom: tokens.$layout-sm;
  }
}

.homeLink {
  display: none;

  @media screen and (min-width: calc(tokens.$screen-xl + 1px)) {
    align-items: center;
    background: tokens.$color-white;
    border-bottom: 1px solid tokens.$color-grey-20;
    box-shadow: tokens.$box-shadow-xs;
    display: flex;
    height: tokens.$tab-bar-height;
    padding: 0 tokens.$spacing-lg;

    img {
      display: block;
      max-width: 100%;
    }

    &:focus {
      outline-offset: calc(-1 * tokens.$border-focus-width);
      outline: tokens.$border-focus-width solid tokens.$color-purple-10;
    }
  }
}

.navbarList {
  border-bottom: 1px solid tokens.$color-grey-10;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-xs;
  list-style-type: none;
  padding: tokens.$spacing-sm tokens.$spacing-md;

  @media screen and (min-width: tokens.$screen-xl) {
    border: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-xs;
  }

  hr {
    border: none;
    background-color: tokens.$color-grey-10;
    display: block;
    height: 1px;
    margin: tokens.$spacing-sm 0;
    width: 100%;
  }

  strong {
    color: tokens.$color-grey-40;
    font-weight: 600;
  }

  a {
    border-radius: calc(tokens.$border-radius-xs + tokens.$border-radius-sm);
    color: tokens.$color-black;
    display: flex;
    font-weight: 600;
    gap: tokens.$spacing-md;
    padding: tokens.$spacing-sm tokens.$spacing-md tokens.$spacing-sm
      tokens.$spacing-sm;
    text-decoration: none;

    &.isActive {
      background-color: tokens.$color-grey-10;
    }

    &:hover {
      background-color: tokens.$color-purple-05;
      color: tokens.$color-purple-70;
    }

    &:focus {
      outline: tokens.$border-focus-width solid tokens.$color-purple-10;
    }
  }
}

.content {
  background-color: tokens.$color-grey-05;
  display: flex;
  flex: 1 1 auto;

  .page {
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.footer {
  background-color: tokens.$color-black;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: tokens.$spacing-xl;
  padding-block: tokens.$spacing-xl;

  .mozillaLink {
    padding: 0 tokens.$spacing-md;

    &:hover img {
      background-color: tokens.$color-purple-50;
    }
  }

  .externalLinks {
    flex: 1 0 auto;
    display: flex;
    padding: 0;
    list-style-type: none;
    flex-direction: column;

    a {
      display: block;
      color: tokens.$color-grey-05;
      font-weight: 500;
      text-decoration: none;
      padding: tokens.$spacing-sm tokens.$spacing-md;

      &:hover {
        color: tokens.$color-purple-50;
      }
    }
  }

  @media screen and (min-width: tokens.$screen-lg) {
    flex-direction: row;
    align-items: center;
    padding-block: 0;

    .externalLinks {
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 tokens.$spacing-2xl;
    }

    .mozillaLink {
      padding: tokens.$spacing-md tokens.$spacing-2xl;
    }
  }
}
