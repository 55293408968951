@use "../../../../../tokens";

.wrapper {
  background-color: tokens.$color-grey-05;
  display: flex;
  flex-direction: column;
  gap: tokens.$layout-md;
  min-height: 100%;
  padding: tokens.$layout-lg tokens.$layout-2xl;

  @media screen and (max-width: tokens.$screen-lg) {
    padding: tokens.$spacing-lg;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-lg;
  }

  table {
    border-collapse: collapse;
    font: tokens.$text-body-sm;

    th,
    td {
      border: 1px solid tokens.$color-grey-60;
      padding: tokens.$spacing-xs tokens.$spacing-sm;
      text-align: left;
    }
  }
}

.header {
  font: tokens.$text-title-xs;
  font-weight: normal;

  b {
    font-weight: bold;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;

  .userPicker {
    flex: 1 0 auto;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: tokens.$spacing-lg;

    label {
      display: flex;
      flex-direction: column;
      gap: tokens.$spacing-sm;
      min-width: 50%;
    }

    input {
      padding: tokens.$spacing-sm;
      font: tokens.$text-body-md;
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: tokens.$spacing-sm;
}

.status {
  background-color: tokens.$color-yellow-05;
  border: 2px solid tokens.$color-yellow-20;
  border-radius: tokens.$border-radius-sm;
  padding: tokens.$spacing-md tokens.$spacing-lg;
  font: tokens.$text-body-md;
}

.content {
  display: flex;
  gap: tokens.$spacing-lg;
}

.editInputs {
  display: grid;
  font: tokens.$text-body-sm;
  gap: tokens.$spacing-md;
  grid-template-columns: repeat(2, 1fr);
}
