@use "../../../../../tokens";

.wrapper {
  width: 100%;
  height: 100%;
  background-color: tokens.$color-grey-05;
  overflow: auto;

  .tabBar {
    height: tokens.$tab-bar-height;
    border-bottom: 1px solid tokens.$color-grey-20;
    display: flex;
    align-items: center;

    .start {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      padding-inline: tokens.$spacing-2xl;
    }

    .end {
      padding-inline: tokens.$spacing-xl;
    }

    @media screen and (max-width: tokens.$screen-md) {
      .start {
        justify-content: center;
      }

      .end {
        display: none;
      }
    }
  }

  .main {
    padding: tokens.$spacing-lg 0;
  }

  th,
  td {
    border: 1px solid;
    padding: 10px;
  }

  h3 {
    padding: 0 tokens.$spacing-2xl;
    font: tokens.$text-title-2xs;
  }
}

.flagList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(tokens.$content-xs, 1fr));
  justify-content: flex-start;
  gap: tokens.$spacing-lg;
  padding: tokens.$spacing-xl;
}
